<template>
  <div>
    <div class="lg:hidden">
      <div
        class="
          px-6
          py-2
          flex flex-col
          font-semibold
          flex-grow
          lg:min-h-50vh lg:mt-32
        "
      >
        <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Security Question</h2>
        </header>
        <BPageLoader v-if="isLoading" />
        <main class="my-6 flex-grow flex flex-col text-sm" v-else>
          <div class="flex-grow">
            <div class="flex flex-col">
              <label class="text-text-primary font-semibold"
                >Security Question</label
              >
              <BModalSelect
                class="my-2"
                :options="questions"
                placeholder="Choose"
                v-model="question"
              />
            </div>
            <div class="flex flex-col my-3">
              <label class="text-text-primary font-semibold">Answer</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-3.5
                  text-sm
                  font-medium
                  focus:outline-none
                "
                v-model="answer"
                required
              />
            </div>
          </div>
          <div class="text-center">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary text-sm
                rounded
                w-full
                my-8
                py-4
                font-bold
              "
              type="submit"
              @click="handleSubmit"
              :disabled="!isFilled || securityResponse.isLoading"
            >
              <BButtonLoader
                class="h-4 w-4 mx-auto"
                v-if="securityResponse.isLoading"
              />
              <span v-else>Save Question</span>
            </button>
          </div>
        </main>
      </div>
    </div>

    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col font-semibold flex-grow">
          <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Security Question</h2>
          </header>
          <BPageLoader v-if="isLoading" />
          <main class="my-6 flex-grow flex flex-col text-sm" v-else>
            <div class="flex-grow">
              <div class="flex flex-col">
                <label class="text-text-primary font-semibold"
                  >Security Question</label
                >

                <BSelectInput
                  class="my-2"
                  :options="questions"
                  placeholder="Choose"
                  v-model="question"
                />
              </div>
              <div class="flex flex-col my-3">
                <label class="text-text-primary font-semibold">Answer</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-3.5
                    text-sm
                    font-medium
                    focus:outline-none
                  "
                  v-model="answer"
                  required
                />
              </div>
            </div>
            <div class="text-center">
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary text-sm
                  rounded
                  w-full
                  my-8
                  py-4
                  font-bold
                "
                type="submit"
                @click="handleSubmit"
                :disabled="!isFilled || securityResponse.isLoading"
              >
                <BButtonLoader
                  class="h-4 w-4 mx-auto"
                  v-if="securityResponse.isLoading"
                />
                <span v-else>Save Question</span>
              </button>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, watch } from 'vue';
import BModalSelect from '@/components/ui/BModalSelect';
import { useApi } from '@/cmp-functions/useApi';
import {
  getSecurityQtn,
  postSecurityQtn,
  updateSecurityQtn,
  getUserSecurity,
} from '@/services/api';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import BSelectInput from '../../../components/ui/BSelectInput.vue';

const userSecurityUpdate = ({ id, payload }) => {
  return id
    ? updateSecurityQtn({ userId: payload.userId, payload })
    : postSecurityQtn(payload);
};

export default {
  name: 'Questions',

  components: { BModalSelect, BSelectInput },

  setup() {
    const question = ref('');
    const answer = ref('');
    const store = useStore();
    const authUser = store.state.auth.authData;
    const toast = useToast();

    const isFilled = computed(() => {
      return question.value && answer.value;
    });

    const [response, fetchSecurityQtn] = useApi(getSecurityQtn);
    const [securityResponse, postSecurity] = useApi(userSecurityUpdate);
    const [userSecurityResponse, fetchUserSecurity] = useApi(getUserSecurity);
    fetchSecurityQtn();
    fetchUserSecurity(authUser.id);

    const questions = computed(() =>
      response.data?.map(({ question }) => question)
    );

    watch(securityResponse, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Saved!');
      }
    });

    const handleSubmit = () => {
      if (isFilled.value) {
        const { id: questionId } = response.data.find(
          (option) => option.question === question.value
        );
        const payload = {
          userId: authUser.id,
          answer: answer.value,
          questionId,
        };
        postSecurity({ id: userSecurityResponse.data.id, payload });
      }
    };

    return {
      ...toRefs(response),
      securityResponse,
      userSecurityResponse,
      isFilled,
      answer,
      questions,
      question,
      handleSubmit,
    };
  },
};
</script>
